import React from 'react';
import IndexView from 'views/IndexView';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const IndexPage = () => {
  return (
    <WithLayout
      component={IndexView}
      layout={Main}
    />
  )
};

export default IndexPage;
/*


import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import { Box, Typography } from '@material-ui/core';
import { Parallax } from 'components/organisms';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';

const useStyles = makeStyles(theme => ({
    sectionAlternate: {
        background: 'transparent',
        backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.dark} 0%)`,
    },
}));


const StrongEye = () => {
    const classes = useStyles();

    const [lineHeight, setLineHeight] = React.useState(5);
    const handlePeek = () => {
        if (lineHeight === 5) {
            setLineHeight(300);
        } else {
            setLineHeight(5);
        }
    };
    const handleHide = () => {
        setLineHeight(5);
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>StrongEye Inc., : On-demand technical leadership, fractional CTO consultancy</title>
                <meta name="description"
                    content="On-demand technical leadership, software planning, and fractional CTO consulting services."></meta>
            </Helmet><Box marginBottom={2} padding={2} paddingTop={10} >
                <a href="https://www.linkedin.com/in/strongeyesteve">
                    <img src="/img/strongeye_software_project_expertise.png" alt="StrongEye software project leadership on-demand" />
                </a>
            </Box>
            <Box marginBottom={2} padding={2} paddingTop={10} >
                <a href="https://www.linkedin.com/in/strongeyesteve">
                    <img src="/img/Bug_500px.png" style={{'opacity':0.1}}/>
                </a>
            </Box>
            <Box marginBottom={2} padding={2} onClick={handlePeek} onMouseOut={handleHide}>
                <Parallax backgroundImage="/img/line.png" >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#007b3d3d' }}>
                        <CardBase style={{ height: lineHeight, background: 'transparent' }}>
                            <div> </div>
                        </CardBase>
                    </div>
                </Parallax>

            </Box>
            <Box marginBottom={2} padding={2} paddingTop={10} >
                <a href="https://www.linkedin.com/in/strongeyesteve">
                    <img src="/img/Bug_500px.png" />
                </a>
            </Box>
            <Box marginBottom={6000} padding={2} >
                <div></div>
            </Box>
        </div>)
        ;
}

export default StrongEye;
*/