/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 * 
 */
import React from 'react';
import Agency from 'views/Agency';
import StrongEye from 'views/StrongEye';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const IndexPage = () => {
  return (
    <WithLayout
      component={StrongEye}
      layout={Main}
    />
  )
};

export default IndexPage;
